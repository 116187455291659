import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Tarta from "../static/tarta"

const IndexPage = () => (
  <Layout>
    <div className="text-center my-10">
      <div className=" text-4xl font-bold ">Feliz cumpleaños <span className="font-bold text-purple-500">MARINA</span></div>
      <div className=" grid grid-cols-1 md:grid-cols-2">
        <div>
          <Tarta />
        </div>
        <div className="p-4 md:p-8">
          <p className="text-lg ">Esta sera la futura página de tu futuro <span className="font-bold text-purple-500">negocio</span></p>
          <p>Donde podras vender todos tus servicios</p>
          <ul className=" list-disc px-16 py-2 text-left">
            <li className="text-sm  md:text-md">Depilacion Cejas <span className="text-right">15 €</span></li>
            <li className="text-sm  md:text-md">Microblading <span className="text-right">300 €</span></li>
            <li className="text-sm  md:text-md">Shading <span className="text-right">150 €</span></li>
            <li className="text-sm  md:text-md">Personal Shopper <span className="text-right">50 €</span></li>
          </ul>
          <p className="my-2">Podras subir las fotos de tus modelos para que todo el mundo quiera apuntarse</p>
          <p className="my-2">Ademas quiero ponerte un sistema de <span className="font-bold text-purple-500">reserva online</span> para que puedan pedirte la cita en la web solo con su telefono</p>
          <p className="my-2">Vamos a tener que diseñar un <span className="font-bold text-purple-500">logo</span> molón y buscar un sistema de colores para todo el <span className="font-bold text-purple-500">BRANDING</span> de tu marca personal.</p>
          <p className="my-2">El branding incluye tanto una gama de colores, como la tipografia,los tamaños y espacios, el logo, las bases para tus posts de instagram etc</p>    
          <p className="my-2">Tendras ademas tu correo profesional <span className="font-bold text-orange-500">contacto@marinamoreno.es</span></p>  
          <p className="my-2">Incluye la posibilidad de una cuenta de instagram de  <span className="font-bold text-green-800">6.000 followers</span> depende de si quieres usar la tuya o no</p>    
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
