import React from "react"

function Icon() {
  return (
    <svg
      viewBox="0 0 779.838 621.86"
    >
      <path
        fill="#f2f2f2"
        d="M775.025 1.7q-14.595 9.42-29.31 18.08c-.71.42-1.42.84-2.13 1.25q-18.3 10.71-36.75 20.2c-.61.32-1.22.63-1.83.94q-30.78 15.75-61.78 28.09c-.7.28-1.4.56-2.1.83q-19.185 7.56-38.39 13.78c-.69.22-1.39.45-2.08.66-.79.26-1.58.51-2.37.76a617.188 617.188 0 01-65.77 16.85c-.82.16-1.63.32-2.44.48q-22.665 4.38-45.47 7c-.69.09-1.37.17-2.06.24a585.81 585.81 0 01-65.06 3.63c-51.26 0-103.62-6.78-156.01-20.18-.75-.19-1.5-.38-2.25-.59q-13.26-3.42-26.53-7.43-8.325-2.52-16.7-5.27c-.67-.22-1.34-.44-2.01-.67q-35.61-11.805-71.71-27.8c-.73-.32-1.46-.65-2.2-.98q-21.735-9.69-43.57-20.88-.93-.465-1.86-.96-25.32-13.02-50.67-28.01L44.995 0q26.235 15.51 52.37 28.87l.05.02q21.09 10.8 42.11 20.18a789.385 789.385 0 0074.4 28.91c1.67.55 3.33 1.1 5 1.62q21.15 6.84 42.23 12.21c.76.2 1.51.39 2.27.58q110.385 27.81 218.17 16.07c.69-.06 1.37-.14 2.06-.23q21.465-2.385 42.8-6.38c2.45-.46 4.9-.93 7.35-1.43a617.47 617.47 0 0065.21-16.77c.69-.22 1.39-.44 2.08-.67q20.205-6.435 40.28-14.33c.7-.28 1.4-.55 2.1-.83q30.225-11.985 60.15-27.24c.61-.3 1.22-.61 1.83-.94q19.26-9.855 38.38-21.08c.72-.41 1.43-.83 2.14-1.26q14.024-8.28 27.97-17.28z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M139.525 49.07l-42.11-20.18-.05-.02-2.34-1.12-.38 1.98-11.58 60.21 59.21-37.39 1.95-1.23zm-53.62 36.71l10.6-55.09v-.02l43.59 20.89-.02.01zM261.155 91.81l-1.93 1.91-41.88 41.29-1.35-53.99-.05-2.12 2.98.7c-1.67-.52-3.33-1.07-5-1.62l.06 2.37 1.48 59.32 46.01-45.36 1.95-1.92c-.76-.19-1.51-.38-2.27-.58zM526.455 101.85l4.72-.57-1.1 2.34-26.28 55.62-19.19-48.62-.95-2.39c-.69.09-1.37.17-2.06.23l.95 2.4 21.09 53.41 28.88-61.13 1.29-2.72c-2.45.5-4.9.97-7.35 1.43zM641.375 68.65l-.25 2.44-5.25 51.83-33.14-38.05-1.64-1.89c-.69.23-1.39.45-2.08.67l1.64 1.88 36.74 42.19 5.83-57.46.25-2.44c-.7.28-1.4.55-2.1.83zM743.835 18.56l-.25 2.47-5.8 55.9-30.95-35.7-1.38-1.59c-.61.33-1.22.64-1.83.94l1.38 1.59 34.29 39.56 6.42-61.95.26-2.48c-.71.43-1.42.85-2.14 1.26zM483.327 372.11c-12.273-10.37-19.727-26.752-17.772-42.7s14.063-30.61 29.77-33.996 33.61 5.988 38.38 21.33c2.626-29.58 5.651-60.4 21.378-85.59 14.24-22.809 38.905-39.13 65.635-42.05s54.875 8.108 71.546 29.206 20.752 51.77 9.077 75.993c-8.602 17.844-24.448 31.123-41.051 41.927a264.939 264.939 0 01-181.202 39.641z"
      ></path>
      <path
        fill="#fff"
        d="M665.83 197.824a433.479 433.479 0 00-60.41 59.986 434.742 434.742 0 00-68.04 113.703c-1.225 3.002 3.65 4.31 4.862 1.34A432.297 432.297 0 01669.395 201.39c2.517-2.05-1.068-5.598-3.565-3.565z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M228.91 537.87q-3.75.555-7.52.99a265.107 265.107 0 01-173.68-40.63c-16.6-10.8-32.45-24.08-41.05-41.93-11.68-24.22-7.59-54.89 9.08-75.99a75.823 75.823 0 0122.03-18.78c1.26-.73 2.54-1.41 3.84-2.06a82.631 82.631 0 0145.67-8.37c26.73 2.92 51.4 19.25 65.64 42.05 15.72 25.19 18.75 56.01 21.37 85.59.21-.68.45-1.35.71-2a26.908 26.908 0 012-4.02v49.14a15.018 15.018 0 0015 15h35.77z"
      ></path>
      <path
        fill="#fff"
        d="M42.17 359.824a433.478 433.478 0 0160.41 59.986 439.673 439.673 0 0147.64 70.507 437.872 437.872 0 0120.4 43.196c1.225 3.002-3.65 4.31-4.862 1.34a430.274 430.274 0 00-39.315-74.386 432.98 432.98 0 00-52.729-65.138 431.944 431.944 0 00-35.11-31.94c-2.516-2.05 1.07-5.598 3.566-3.565z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M521.5 424.86H262v-28.5a9.5 9.5 0 00-19 0v38a9.528 9.528 0 009.5 9.5h269a9.5 9.5 0 000-19z"
      ></path>
      <path
        fill="#3f3d56"
        d="M778.647 621.799H40.353a1.19 1.19 0 010-2.382h738.294a1.19 1.19 0 010 2.382z"
      ></path>
      <path
        fill="#3f3d56"
        d="M690.999 536.86h-563a17.024 17.024 0 00-17 17v51a17.024 17.024 0 0017 17h563a17.024 17.024 0 0017-17v-51a17.024 17.024 0 00-17-17zm15 68a15.018 15.018 0 01-15 15h-563a15.018 15.018 0 01-15-15v-51a15.018 15.018 0 0115-15h563a15.018 15.018 0 0115 15z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M663.5 590.86H143v-28.5a9.5 9.5 0 00-19 0v38a9.528 9.528 0 009.5 9.5h530a9.5 9.5 0 000-19zM570.5 502.86H208v-28.5a9.5 9.5 0 00-19 0v38a9.528 9.528 0 009.5 9.5h372a9.5 9.5 0 000-19z"
      ></path>
      <path
        fill="#6c63ff"
        d="M605.919 568.93c-80-20-250 8-253 8-2.93 0-188.91 27.65-182.78-38.07.06-.66.14-1.32.24-2h499.23c.12.59.24 1.26.35 2 1.93 13.03.98 46.33-64.04 30.07z"
      ></path>
      <path
        fill="#3f3d56"
        d="M627.999 453.86h-436a17.024 17.024 0 00-17 17v51a17.04 17.04 0 0017 17h436a17.064 17.064 0 0017-17v-51a17.024 17.024 0 00-17-17zm15 68a15.031 15.031 0 01-15 15h-436a15.03 15.03 0 01-15-15v-51a15.018 15.018 0 0115-15h436a15.018 15.018 0 0115 15z"
      ></path>
      <path
        fill="#6c63ff"
        d="M572.53 481.961c-69.924-17.481-218.514 6.992-221.136 6.992-2.561 0-165.118 24.168-159.76-33.275.052-.577.122-1.154.21-1.748h436.355c.105.516.21 1.101.306 1.748 1.687 11.389.856 40.495-55.975 26.283z"
      ></path>
      <path
        fill="#3f3d56"
        d="M574 454.86H245a17.02 17.02 0 01-17-17v-51a17.02 17.02 0 0117-17h329a17.02 17.02 0 0117 17v51a17.02 17.02 0 01-17 17zm-329-83a15.017 15.017 0 00-15 15v51a15.017 15.017 0 0015 15h329a15.017 15.017 0 0015-15v-51a15.017 15.017 0 00-15-15z"
      ></path>
      <path
        fill="#6c63ff"
        d="M534.68 391.463c-53.62-13.405-167.56 5.362-169.57 5.362-1.964 0-126.615 18.532-122.506-25.516.04-.443.093-.885.16-1.34h334.603c.08.395.16.844.234 1.34 1.294 8.733.657 31.052-42.922 20.154z"
      ></path>
      <ellipse cx="409.919" cy="371.93" fill="#3f3d56" rx="34" ry="3"></ellipse>
      <path
        fill="#ffb8b8"
        d="M408 260.577a10.527 10.527 0 00.05 1.657l-37.988 31.88-11.328-4.1-8.425 16.287 24.767 11.789 41.53-46.603a10.496 10.496 0 10-8.606-10.91z"
      ></path>
      <path
        fill="#2f2e41"
        d="M357.052 314.113l-36.684-13.24a13.5 13.5 0 119.167-25.397l36.683 13.24a4.499 4.499 0 012.705 5.76l-6.11 16.932a4.506 4.506 0 01-5.761 2.705z"
      ></path>
      <path
        fill="#3f3d56"
        d="M407 235.86a1 1 0 01-1-1v-37a1 1 0 012 0v37a1 1 0 01-1 1z"
      ></path>
      <path
        fill="#ff6584"
        d="M424 189.86c9.306 8.646-2.298 27-15 27a23 23 0 01-23-23c0-12.703 24.858-68.737 20-57-1.55 3.746 7.343 43.099 18 53z"
      ></path>
      <path
        fill="#ccc"
        d="M416 335.36h-17a4.505 4.505 0 01-4.5-4.5v-102a4.505 4.505 0 014.5-4.5h17a4.505 4.505 0 014.5 4.5v102a4.505 4.505 0 01-4.5 4.5z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M330.185 443.105L342.445 443.105 348.277 395.817 330.183 395.818 330.185 443.105z"
      ></path>
      <path
        fill="#2f2e41"
        d="M366.09 454.488l-38.531.001-.001-14.886 23.644-.001a14.887 14.887 0 0114.887 14.886z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M299.887 427.268L309.916 434.32 341.887 398.992 327.085 388.585 299.887 427.268z"
      ></path>
      <path
        fill="#2f2e41"
        d="M322.71 457.231l-31.52-22.161 8.562-12.178 19.342 13.599a14.887 14.887 0 013.616 20.74z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M357.012 357.922s30.595-3.61 25.103 16.243-66.681 37-66.681 37z"
      ></path>
      <circle cx="335.954" cy="232.059" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#2f2e41"
        d="M257.627 407.1c-13.976-16.274-18.866-41.25 3.421-62.022a73.46 73.46 0 0118.485-12.275l17.962-50.235a27.374 27.374 0 0125.297-18.196 26.197 26.197 0 0125.412 16.343 22.739 22.739 0 01.706 16.103 258.67 258.67 0 01-20.959 47.954l.929 7.43 45.403 4.036-.423.689c-.221.359-22.442 34.215-50.87 56.688-9.72 7.684-19.77 10.868-29.256 10.867-14.385 0-27.469-7.324-36.107-17.383z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M409.23 294.877a10.527 10.527 0 00-.659 1.52l-47.936 12.712-8.51-8.527-14.55 11.162 17.409 21.198 57.4-24.532a10.496 10.496 0 10-3.153-13.533z"
      ></path>
      <path
        fill="#2f2e41"
        d="M340.359 321.681l-27.578-27.577a13.5 13.5 0 1119.092-19.091l27.577 27.577a4.499 4.499 0 010 6.363l-12.727 12.728a4.506 4.506 0 01-6.364 0zM253.342 239.764c4.345-5.178 6.209-11.95 7.708-18.542 2.372-10.428 4.208-21.212 9.479-30.519 5.27-9.306 14.955-17.017 25.634-16.43 10.619.583 19.203 9.126 23.76 18.846a8.452 8.452 0 016.863.33c3.285 1.8 4.513 6.911 1.779 9.47a22.69 22.69 0 0118.477-.46 22.335 22.335 0 0113.53 18.954 12.177 12.177 0 01-3.238 9.135c-4.713 4.852-11.378 3.178-17.304 1.942-5.598-1.167-12.415-1.482-15.569 3.851a9.996 9.996 0 00-.972 7.306c1.208 5.324 5.15 9.032 8.974 12.581l.625 1.162c-13.502 2.633-28.247-6.588-31.789-19.881s4.66-28.628 17.682-33.063a4.87 4.87 0 01-4.372-7.296c-3.75-1.875-7.687-3.219-11.815-2.948-6.727.441-12.548 5.23-16.049 10.99-3.5 5.761-5.085 12.448-6.691 18.995-1.606 6.547-3.344 13.22-7.087 18.826a29.959 29.959 0 01-47.999 2.462 23.909 23.909 0 0028.374-5.711z"
      ></path>
    </svg>
  )
}

export default Icon
